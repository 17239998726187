<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card>
            <v-card-title class="px-4 py-4 align-center" style="gap: 8px;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="pt-0"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <h5 class="text-h6 secondary--text text-uppercase font-weight-bold">
                    Agregar financiamiento
                </h5>
            </v-card-title>
            <v-divider />
            <v-card-text class="mt-4">
                <v-form @submit.prevent="enviarFormulario">
                    <v-autocomplete
                        v-model="form.financiamiento"
                        :items="cifradosPresupuestarios"
                        :loading="cifrados.isLoading"
                        :error-messages="cifradoPresupuestarioErrors"
                        outlined
                        label="Cifrado presupuestario"
                        item-value="id"
                        item-text="nombre"
                        return-object
                        clearable
                        @blur="$v.form.financiamiento.$touch()"
                    />
                    <v-text-field 
                        v-model="form.monto"
                        :disabled="!form.financiamiento"
                        label="Monto a utilizar"
                        outlined
                        type="text"
                        :error-messages="montoErrors"
                        @input="$v.form.monto.$touch()"
                        @blur="$v.form.monto.$touch()"
                    />

                    <div v-if="form.financiamiento">
                        <v-list subheader>
                            <v-subheader class="px-0 justify-space-between align-center" style="height: auto !important;">
                                <p class="mb-0 text-subtitle-1 font-weight-bold">{{ form.financiamiento.tipo_fondo }}</p>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p 
                                            v-on="on"
                                            v-bind="attrs"
                                            class="mb-0 font-weight-bold" 
                                            style="color: var(--v-success-darken2); font-size: 22px !important;"
                                        >
                                            ${{ toMoneyFormat(form.financiamiento.monto_disponible) }}
                                        </p>
                                    </template>
                                    <span>Monto disponible</span>
                                </v-tooltip>
                            </v-subheader>
                            <v-divider class="mb-1 mt-1" />
    
                            <v-list-item 
                                v-for="construccion in form.financiamiento?.construccion" 
                                :key="construccion.id"
                                class="pl-1"
                            >
                                <v-list-item-action class="mx-0 my-0">
                                    <v-icon small>mdi-square-medium</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="ml-1 py-0">
                                    <v-list-item-title>
                                        <b>{{ construccion.codigo }} - </b>
                                        {{ construccion.nombre }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>

                    <div class="d-flex justify-space-between align-center mt-4" style="gap: 16px;">
                        <v-btn
                            large
                            color="secondary" 
                            class="flex-grow-1 flex-shrink-1"
                            @click.stop="cerrarModal"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            large
                            color="primary" 
                            class="flex-grow-1 flex-shrink-1"
                            type="submit"
                        >
                            Guardar
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import { required, decimal, maxValue } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation";

const dataInicialForm = {
    financiamiento: null,
    monto: null,
}

export default {
    name: 'ModalAgregarFinanciamiento',
    emits: ['on-save'],
    components: { MoneyTextFieldComponent },
    validations() {
        return ({
            form: {      
                financiamiento: { required },
                monto: { required, decimal, maxValue: maxValue(this.form.financiamiento?.monto_disponible ?? 0) },
            },
        });
    },
    props: {
        isOpen: { type: Boolean },
        cifrados: { type: Object },
        financiamientos_agregados: { type: Array },
    },
    data: () => ({
        // UI
        form: { ...dataInicialForm },
    }),
    computed: {
        // Data
        cifradosPresupuestarios() {
            return this.cifrados.data
                .filter((cifrado) => !this.financiamientos_agregados?.includes(cifrado.id))
                .map((cifrado) => ({ ...cifrado, nombre: `${cifrado.tipo_fondo} - ${cifrado.cifrado}` })); 
        },
        // Validaciones
        cifradoPresupuestarioErrors() {
            return new Validator(this.$v.form.financiamiento).detect().getResult();
        },
        montoErrors() {
            return new Validator(this.$v.form.monto).detect().getResult();
        },
    },
    methods: {
        // UI
        toMoneyFormat(value) {
            return Intl.NumberFormat("en-US", { currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value || 0);
        },
        limpiarCampos() {
            this.form = { ...dataInicialForm };
            this.$v.form.$reset();
        },
        cerrarModal() {
            this.limpiarCampos();
            this.$emit('update:is-open', false);
        },
        enviarFormulario() {
            this.$v.form.$touch();

            if (this.$v.form.$invalid) return;

            this.$emit('on-save', { ...this.form });
            this.cerrarModal();
        },
    },
}
</script>